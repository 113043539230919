const alertBenner = document.querySelector(".alert-banner");
if (alertBenner !== null) {
    let checkCookie = getGlobalAlertCookie();
    console.log('global cookie here', checkCookie);
    if (checkCookie === null) {
        alertBenner.classList.toggle("dismissed");
    }
}

function setMainContentAreaPosition() {
    const header = document.getElementById("header");
    const main = document.getElementById("main");

    const headerHeight = header.getBoundingClientRect()?.height;
    main.setAttribute("style", `top:${headerHeight}px`);
}

const alertDismiss = document.querySelector(".alert-banner__dismiss");
if (alertDismiss !== null) {
    alertDismiss.addEventListener("click", (element) => {
        createGlobalAlertCookie()
        if (alertBenner !== null) {
            alertBenner.classList.toggle("dismissed");
        }

        // when alert banner is closed - set main top value based on header height
        setMainContentAreaPosition();
    });
}

function createGlobalAlertCookie() {
    var expire = new Date();    
    expire.setHours(expire.getHours() + (24)); // Default 24h
    cookieExpires = expire;
    document.cookie = "UCSD-Health-GlobalAlert=" + "" + "; expires=" + cookieExpires + "; path=/";  
}

function getGlobalAlertCookie() {
    var nameEQ = "UCSD-Health-GlobalAlert=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}